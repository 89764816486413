import $ from 'jquery'
window.onscroll = function () {
	var x = document.documentElement.scrollTop || document.body.scrollTop;
	if (x > 375) {
		$(".thead-fixed2").removeClass("no");
		$(".thead-fixed2").addClass("title-guding");

	} else {
		//$(".thead-fixed2").removeClass("title-guding");
		$(".thead-fixed2").addClass("no");
		//$(".thead-fixed1").removeClass({"display":"no"});
	}
}