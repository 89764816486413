<!-- 双色球走势图 -->
<template>
    <div class="ssq">
        <ssqheader></ssqheader>
        <div class="clear"></div>
    </div>
    <router-view />
    <smFooter></smFooter>
    <Footer></Footer>
</template>

<script>
    import '../../js/ssqtable-thead-fixed.js' //js文件引入，注意路径
    import ssqheader from '@/components/ssq/ssq-header.vue'
    import smFooter from '@/components/common/exp.vue'
    import  Footer from '@/components/Footer.vue'
    export default {
        name: 'ssq',
        components: {
            ssqheader,
            smFooter,
            Footer
        },
        data() { // 数据源
            return {
               
            }
        },
        mounted() {
           // this.$options.methods.sqb()
          
        },
        computed: {
           
        },
        methods: { // 函数体部分，js的主要逻辑控制 
        
          
        
            
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    /* *{
    border:1px solid red;
} */
   
   
</style>